<template>
    <svg 
     width="15px"
     height="15px" 
     viewBox="0 0 15 15" 
     version="1.1" 
     xmlns="http://www.w3.org/2000/svg" 
     xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: sketchtool 63.1 (101010) - https://sketch.com -->
    <!-- <title>01EBE0B5-5576-47B4-A7BB-E3BDC95CE99C</title> -->
    <desc>Created with sketchtool.</desc>
    <g id="Page-1" stroke="#2A2B75" stroke-width="1" fill="none" fill-rule="evenodd" class="closeLightPopup">
        <g id="Spec" transform="translate(-815.000000, -737.000000)" fill="#EFEFF4" fill-rule="nonzero">
            <g id="Close" transform="translate(808.000000, 729.711481)">
                <polygon id="Combined-Shape" points="21.7235724 19.6022521 19.6022521 21.7235724 14.781 16.902 10.1014904 21.5825403 7.98017002 19.46122 12.659 14.781 7.83913794 9.96045828 9.96045828 7.83913794 14.781 12.659 19.46122 7.98017002 21.5825403 10.1014904 16.902 14.781" fill="none" class="closedarklight" ></polygon>
            </g>
        </g>
    </g>
</svg>
    
</template>
<script>

export default{
    name:"closepopup"
}
</script>
<style lang="scss">
@import "../../sass/_variables.scss";


.closeLightPopup{
 stroke:$clr-Closelight-popup;
}
.closedarklight{
fill:$clr-close-light-dark;
}

</style>